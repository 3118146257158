/*  
Command Line 'sass --watch App.scss App.css' 

 
*/

/*****  mixin's *********/

@mixin no-padding-margin-top-bottom {
  padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
}


/******* Global  ********/

body {
  margin: 0;
  font-size: 15px;
  font-family: Helvetica, Helvetica Neue, Arial, Lucida Grande, sans-serif;
  font-style: normal;
  width: 100%;
}

  ul, li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
  /*
  #root {
    background: radial-gradient(#f1f0ed, #dcdad7);
   
  }
  */
  
/************** HEADER ********** */
  
  .headerLogo {
    width: 50px;
    height: 50px;
  }
  
  .header {
    padding: 20px;
    min-height: 4vh;
    max-height: 5vh;
    margin-top: 0;
    margin-bottom: 0;
    
    display: flex;

    
    justify-content: space-between;

    ul {
      display: flex;
      width: 400px;
      justify-content: space-around;

      li {
        width: 100px;
        a {
        align-items: center;
        }
      }
     
    
    
    }

  }





  .footer {
    display: flex;
    position: fixed; bottom: 0; left: 0; right: 0;
    background-color: white;
    min-height: 4vh;
    max-height: 5vh;
    justify-content: center;
    align-items: center;
    
    ul {
      display: flex;
      li {
        width: 15px;
        margin-left: 5px;
        margin-right: 5px;
      }
      
    }
  }
  

  
/*************  Home Page Banner **************/

  .homebanner {
    background-image: url('images/dogbackground4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 80vh;
    max-height: 90vh;
    @include no-padding-margin-top-bottom;

      .bannerbox {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 10px;
        width: 300px;
        position: relative;
        top: 150px; 
        left: 150px;
        background-color: rgba(32, 28, 28, 0.9);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-radius: 5px;
        color: white;

        a {
          background-color: rgb(174, 186, 186);
          color: black;
          padding: 15px;
          margin: 10px;
          font-weight: bold;
          font-size: 1.5em;
        }

        
      }
      h1 {
        @include no-padding-margin-top-bottom;
        text-align: center;
      }
  }

/********** Homepage Banner  - Tablet  *********/

@media screen and (max-width: 768px) {
  .homebanner {


    
    .bannerbox {
      width: 200px;
      top: 100px; 
      left: 100px;


    }
  }
}


/*************  Search Page ****************************/

.searchPage{
  display: flex;
  flex-direction: row;

  .filters {
    padding: 20px;
    //min-height: 4vh;
    //max-height: 5vh;
    margin-top: 0;
    margin-bottom: 0;
    
    display: flex;
    flex-direction: column;
    box-shadow: 10px 5px 5px grey;
    justify-content: flex-start;

    .dogBreedBox {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
      height: 80vh;
      display: inline-flex;
      flex-wrap: no-wrap; 
      flex-direction: column;
      background: grey;
      overflow-y: auto;
      padding: 15px;

      .breedChoice {
        display: flex;
        justify-content: space-between;

        .selectionButtons {
          display: flex;
        };
        
        

      }
    }

   

    .selectedFilters {
      .searchCriteriaSelected {
        display: inline-flex;
        flex-wrap: nowrap;
        background-color:aliceblue;
        margin: 5px;
        padding: 0;
        
        &:hover {
          cursor: pointer;
        }
        
        p { 
          padding: 5px;
          margin: 0;
          
        }
        p:nth-child(2) {
          color: grey;
        }
      }
    }
    
    }

}



/************ Need to Organize ****************/


  .pets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgb(235, 230, 230);

    .petCard {
      display: flex;
      flex-direction: column;
      min-width: 250px;
      //justify-content: center;
      //align-items: center;
      //align-content: center;
      padding: 0px;
      margin: 20px;
      box-shadow: 10px 5px 5px grey;
      background-color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 30px;
      background-color: white;
      
      .petDataimgDiv {
        width: 250px;
        height: 250px;
        margin: 0;
        padding: 0;

      .dogThumbnail {
        width: 100%;
        height: 100%;
    
        margin: 0px;
        padding: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
 

      }
      
      }

      .petData {
        width: 210px;
        height: auto;
        padding: 20px;
        margin: 0;
       
      
        
      
      }
    }
  }
  
  
  
  
  
  

  .petCardPage {
    padding: 20px;
    background-color: rgb(234, 235, 224);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    
    .dogPhotos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .dogPicture {
        width: 15%;
        height: auto;
        margin: 5px;
        padding: 5px;

      }
    }
 
    
  }